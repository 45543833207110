import type { ContextParams, EditorType } from '@wix/platform-editor-sdk';
import type {
  EditorScriptFlowAPI,
  ErrorMonitorProviderProps,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';

import type { Nullable } from '../../types';
import {
  isConflictError,
  runAndWaitForApproval,
} from '../editor-sdk-wrappers/document';
import { toErrorInstance } from './error-builder';

type DefaultMonitoringParams = {
  metaSiteId?: Nullable<string>;
  userId?: Nullable<string>;
  silentInstallation?: Nullable<boolean>;
  type?: Nullable<EditorType>;
};

type DefaultSentryTags = {
  silentInstallation?: Nullable<boolean>;
  type?: Nullable<EditorType>;
};

type CaptureOptions = Parameters<
  ErrorMonitorProviderProps['errorMonitor']['captureException']
>[1] & { extra?: any };

let fedops: EditorScriptFlowAPI['fedops'];
let errorMonitor: EditorScriptFlowAPI['errorMonitor'];
let defaultMonitoringParams: DefaultMonitoringParams | undefined;
let defaultSentryTags: DefaultSentryTags | undefined;

const FAILED_INTERACTION_ERROR = 'Failed to start fedops interaction, reason: ';

export const initializeMonitoring = (
  flowAPI: EditorScriptFlowAPI,
  editorContextParams?: Partial<ContextParams>,
) => {
  fedops = flowAPI.fedops;
  errorMonitor = flowAPI.errorMonitor;
  const silentInstallation = !!editorContextParams?.silentInstallation;
  const type = editorContextParams?.origin?.type ?? null;

  defaultMonitoringParams = {
    metaSiteId: editorContextParams?.initialAppData?.metaSiteId ?? null,
    userId: editorContextParams?.initialAppData?.userId ?? null,
    silentInstallation,
    type,
  };
  defaultSentryTags = {
    silentInstallation,
    type,
  };
};

const getInteractionOptions = (
  customParams?: Record<string, unknown>,
  defaultParams?: DefaultMonitoringParams,
) => {
  return {
    customParams: {
      ...defaultParams,
      ...customParams,
    },
  };
};

const getCaptureOptions = (
  options: CaptureOptions = {},
  defaultTags: DefaultSentryTags = {},
) => {
  const tags = { ...defaultTags, ...(options?.tags ?? {}) };
  return {
    ...options,
    tags,
  };
};

const captureException: ErrorMonitorProviderProps['errorMonitor']['captureException'] =
  (error, data = {}) => {
    const options = getCaptureOptions(data, defaultSentryTags);
    return errorMonitor?.captureException(error, options);
  };

const interactionStarted = (
  interactionName: string,
  customParams?: Record<string, unknown>,
) => {
  try {
    fedops?.interactionStarted(
      interactionName,
      getInteractionOptions(customParams, defaultMonitoringParams),
    );
  } catch (e) {
    const err = new Error(FAILED_INTERACTION_ERROR + e);
    captureException(err);
  }
};

const interactionEnded = (
  interactionName: string,
  customParams?: Record<string, unknown>,
) => {
  try {
    fedops?.interactionEnded(
      interactionName,
      getInteractionOptions(customParams, defaultMonitoringParams),
    );
  } catch (e) {
    const err = new Error(FAILED_INTERACTION_ERROR + e);
    captureException(err);
  }
};

const interactionFailed = (interactionName: string, originalError: unknown) => {
  const error = toErrorInstance(originalError);
  captureException(error, { tags: { interactionName } });
};

export const log = (message: string, data: CaptureOptions = {}) => {
  const options = getCaptureOptions(data, defaultSentryTags);

  console.error({ message, options });
  return errorMonitor?.captureMessage(message, options);
};

export const toMonitored = async <T>(
  interactionName: string,
  action: () => Promise<T> | T,
  customParams?: Record<string, unknown>,
): Promise<T> => {
  try {
    interactionStarted(interactionName, customParams);
    const response = await action();
    interactionEnded(interactionName, customParams);
    return response;
  } catch (err) {
    interactionFailed(interactionName, err as Error);
    throw err;
  }
};

export const withConflictMonitor = async <T>(
  editorSDK: FlowEditorSDK,
  message: string,
  action: () => Promise<T>,
) => {
  try {
    return await action();
  } catch (e: any) {
    if (await isConflictError(editorSDK, e)) {
      const extra = { tags: { error: e.toString() } };
      log(`DS Conflict error in Members Area: ${message}`, extra);
    }
    throw e;
  }
};

export const transactionWithConflictMonitor = async <T>(
  editorSDK: FlowEditorSDK,
  message: string,
  action: () => Promise<T>,
) =>
  withConflictMonitor(editorSDK, message, () =>
    runAndWaitForApproval(editorSDK, action),
  );

export const monitoredTransactionFactory = (editorSDK: FlowEditorSDK) => {
  const transaction =
    <T extends any[], G>(action: (...args: T) => G) =>
    (...props: T) =>
      runAndWaitForApproval<G>(editorSDK, () => action(...props));

  return <T>(
    name: string,
    action: () => Promise<T>,
    customParams?: Record<string, unknown>,
  ) => {
    return toMonitored(
      name,
      transaction(action),
      customParams,
    ) as unknown as Promise<T>;
  };
};
